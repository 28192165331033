import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import { magicMediaQueries, mediaQueries } from 'assets/styles/mediaQueries';
import BaseButton from 'components/Buttons/BaseButton';
import { MAX_CONTENT_WIDTH } from 'components/Container/styles';
import { FormControlWrapper, Icon, Select } from 'components/FormControls/styles';
import { disabledFormMixin } from 'constants/mixins';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export const BetContainer = styled.div<{ $spacing?: string }>`
  margin-top: 0;
  position: relative;
  display: grid;
  gap: 1rem;

  ${magicMediaQueries(Breakpoint.MD)`
    margin-top: 1rem;
    margin-bottom: 0;  
  `}
`;

export const BetContainerNoMargin = styled(BetContainer)`
  margin: 0;
`;

export const BetButtonContainer = styled.div`
  order: -1;

  ${magicMediaQueries(Breakpoint.MD)`
    order: 0;
  `};
`;

// Allows the input to be disabled while a bet is going on
export const InputContainer = styled.div<{ $disabled?: boolean; $noTopMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  & ${FormControlWrapper} {
    margin-top: 1rem;

    &:first-child {
      margin-top: ${({ $noTopMargin }) => ($noTopMargin ? '0' : '1rem')};
    }
  }

  ${({ $disabled }) => $disabled && disabledFormMixin}
`;

export const ShowMoreBackground = styled.div<{ $margin: boolean; $height: number }>`
  padding-top: ${({ $height }) => pxToRem($height / 2)};
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  height: ${({ $height }) => pxToRem($height)};
  min-height: ${pxToRem(90)};
  background: linear-gradient(180deg, rgba(8, 8, 8, 0) 0%, #080808 70%);
  left: 0;
  bottom: 0;
  margin-bottom: ${({ $margin }) => ($margin ? '2rem' : '0rem')};
`;

export const LoadingBtn = styled.div`
  display: flex;
  border-radius: ${pxToRem(6)};
  align-items: center;
  column-gap: ${pxToRem(6)};

  cursor: pointer;
  padding: 1rem;
  font-size: ${UI.text.lg};
`;

// Changes here also affects original game
export const GameWrapperComponent = styled.div<{
  isTheatreMode: boolean;
  containerHeight?: number;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 0 3.125vw;
  margin-top: 3.125vw;

  ${({ isTheatreMode }) => magicMediaQueries(Breakpoint.MD)`
    ${
      isTheatreMode
        ? `
    height: calc(100vh - ${UI.height.navHeader}px);
    width: unset;
    aspect-ratio: 16 / 9;
  `
        : `
    width: ${MAX_CONTENT_WIDTH};
  `
    };
  `}

  ${({ isTheatreMode }) => magicMediaQueries(Breakpoint.SM)`
    margin-top: ${isTheatreMode ? '0' : '2rem'};
    padding: ${isTheatreMode ? '0' : `0 ${UI.page.padding.desktop}`};
  `}

  ${magicMediaQueries(Breakpoint.XL)`
    padding-left: 0;
    padding-right: 0;
  `}
`;

export const GameOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  place-items: center;
  backdrop-filter: blur(10px);
`;

export const GameDiv = styled.div<{
  isTheatreMode: boolean;
  theatreModeHeight?: string;
  isLoading: boolean;
}>`
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16 / 9;

  iframe {
    display: ${({ isLoading }) => (isLoading ? 'none' : 'unset')};
    border: none;
    border-radius: ${({ isTheatreMode }) =>
      isTheatreMode ? 'none' : `${UI.radius.md} ${UI.radius.md} 0 0`};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  ${GameOverlay} {
    border-radius: ${({ isTheatreMode }) =>
      isTheatreMode ? 'none' : `${UI.radius.md} ${UI.radius.md} 0 0`};
  }

  ${mediaQueries(Breakpoint.MD)`
    height: 100%;
    width: 100%;

    iframe {
      height: 100%;
    }
  `}
`;

export const GameOverlayCard = styled.div`
  z-index: 1;
  padding: ${pxToRem(24)};
  background: ${colors.gray900};
  border-radius: ${UI.radius.md};
`;

export const GameOverlayCardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;

  & > div {
    width: auto;
    margin-top: 0 !important; // TODO: FIX
  }
`;

export const GameCardBody = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 1rem;
`;

export const PlayOptionButton = styled(BaseButton)`
  width: 10rem;
`;

export const FooterContainer = styled.div<{ isTheatreMode?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${pxToRem(10)} ${pxToRem(24)};
  height: ${pxToRem(UI.height.providerGameFooter)};
  align-items: center;
  border-radius: ${({ isTheatreMode }) =>
    isTheatreMode ? 'none' : `0 0 ${UI.radius.md} ${UI.radius.md}`};
  background: ${colors.gray900};

  ${magicMediaQueries(Breakpoint.MD)`
    background: url('/images/shuffle-logo-grey.svg') ${colors.gray900} no-repeat center;
  `}
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${pxToRem(18)};
`;

export const FairnessModalBtn = styled.button`
  display: flex;
  align-items: center;
  column-gap: ${pxToRem(3)};
  color: ${colors.white};
  background: none;
  border: none;
  text-decoration: underline;
  color: ${colors.gray300};
  padding: 0;
  cursor: pointer;
  font-weight: 700;
  font-size: ${UI.text.md};
  line-height: 150%;
  user-select: none;
`;

export const SettingsTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pxToRem(138)};
`;

export const SettingsOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & p {
    font-size: ${UI.text.xl};
    margin-right: 2rem;
  }

  svg {
    width: 26px;
  }

  &:not(:last-child) {
    padding-bottom: ${pxToRem(6)};
    border-bottom: 1px solid ${colors.gray100};
  }

  &:last-child {
    padding-top: ${pxToRem(6)};
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

export const GameSelect = styled.div`
  display: flex;
  border-radius: ${UI.radius.sm};
  background: ${colors.gray800};
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: ${pxToRem(4)};
`;

export const GameFooterSelectCurrency = styled.div`
  ${Select}, ${Icon} {
    height: 2.5rem;
  }
`;

export const ProvablyFairGameSelectOption = styled.div<{ $selected?: boolean }>`
  padding: ${pxToRem(10)} ${pxToRem(12)};
  border-radius: ${UI.radius.sm};
  position: relative;
  height: 2rem;
  cursor: pointer;
  display: flex;
  color: ${({ $selected }) => ($selected ? colors.black900 : colors.gray200)};
  align-items: center;
  column-gap: ${pxToRem(6)};
  font-size: ${UI.text.lg};

  background: ${({ $selected }) => ($selected ? colors.white : 'transparent')};
`;

export const OriginalGameContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: ${UI.spacing.lg};
  justify-content: space-between;
  height: 100%;
  min-height: ${pxToRem(380)};

  // Centers the rolling bar
  ${mediaQueries(Breakpoint.MD)`
    &:before {
      content: '';
    }
  `}
`;

const absoluteOriginalGameContentWrapper = `
  position: absolute;
  top: ${UI.spacing.lg};
  right: ${UI.spacing.lg};
`;

export const BetResultContainer = styled.div<{ $isColumn: boolean }>`
  position: relative;

  &:first-of-type {
    animation: slideIn 0.5s ease-in;
    @keyframes slideIn {
      from {
        margin-right: -${({ $isColumn }) => ($isColumn ? '0' : pxToRem(60))};
        margin-top: -${({ $isColumn }) => ($isColumn ? pxToRem(30) : '0')};
      }
      to {
        margin-right: -${({ $isColumn }) => ($isColumn ? '0' : 'unset')};
        margin-top: -${({ $isColumn }) => ($isColumn ? 'unset' : '0')};
      }
    }
  }

  // The last result animates out of view
  &:nth-of-type(6) {
    opacity: 0;
    animation: fadeOut 0.5s ease-out;
    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;

export const OriginalGameResultsWrapper = styled.div<{ isColumn: boolean }>`
  display: flex;
  margin-left: auto;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  height: ${pxToRem(30)}; // height of the ResultBadge
  gap: 0.25rem;
  overflow: hidden;
  // 60px width * 6 + 4px column gap * 5 = 360px
  width: ${pxToRem(380)};

  ${mediaQueries(Breakpoint.MD)`
    ${absoluteOriginalGameContentWrapper}
  `};

  ${({ isColumn }) =>
    isColumn &&
    `
    flex-direction: column;
    width: max-content;
    height: ${pxToRem(200)};
    ${absoluteOriginalGameContentWrapper}
  `}
`;

interface ResultBadgeProps {
  isWin: boolean;
}

export const OriginalGameResultBadge = styled.span<ResultBadgeProps>`
  background-color: ${props => (props.isWin ? colors.success : colors.tertiary)};
  color: ${props => (props.isWin ? colors.black : colors.white)};
  height: ${pxToRem(30)};
  width: ${pxToRem(60)};
  font-size: ${UI.text.lg};
  border-radius: ${UI.radius.sm};
  text-align: center;
  cursor: pointer;
  display: grid;
  place-items: center;
`;

export const ProviderGamePreloadWrapper = styled.div<{ isTheatreMode?: boolean }>`
  height: 100%;
  width: 100%;
  display: none;
  background-color: ${colors.gray900};
  border-radius: ${({ isTheatreMode }) =>
    isTheatreMode ? 'none' : `${UI.radius.md} ${UI.radius.md} 0 0`};
  border-bottom: ${pxToRem(1)} solid ${colors.gray700};

  ${magicMediaQueries(Breakpoint.MD)`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
